import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import ReduxToastr from "react-redux-toastr";
import { ConnectedRouter } from "connected-react-router";

import { ThemeProvider } from "@icr/react.uicomponents";

import App from "./App";
import "./styles/index.scss";
import en from "./constants/locales/en.json";
import { historyS, store } from "./redux/store";

ReactDOM.render(
  <IntlProvider locale="en" defaultLocale="en" messages={en}>
    <Provider store={store}>
      <ThemeProvider>
        <ConnectedRouter history={historyS}>
          <ReduxToastr
            timeOut={0}
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            closeOnToastrClick
          />
          <Route path="/" component={App} />
        </ConnectedRouter>
      </ThemeProvider>
    </Provider>
  </IntlProvider>,
  document.getElementById("root") as HTMLElement
);
