import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { injectIntl } from "react-intl";

import { Form } from "@components/Form";
import { OldButton } from "@components/Button";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { ReactComponent as IconArrowRight } from "@assets/icons/arrow-right.svg";
import { useSwal } from "@hooks/useSwal";

import { useFormatMessage } from "@hooks/formatMessage";

import { TProps } from "./Types";
import { VersionSchema } from "./Schema";
import { changeVersion, createVersion } from "../ducks";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const VersionsModal: FC<TProps> = props => {
  const { applicationList, tenantList, closeModal, initialData } = props;
  const [isEditing, setIsEditing] = useState<boolean>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const formatMessage = useFormatMessage();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Version has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const version = {
      ...formData,
      application: formData.application ? formData.application.value : undefined,
      tenantId: formData.tenantId ? formData.tenantId.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this version?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Version",
      }).then(value => {
        if (value) {
          changeVersion(formData.id, version).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      delete version.id;

      swalConfirmationMessage({
        text: "Do you really want to create this version?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Version",
      }).then(value => {
        if (value) {
          createVersion(version).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            {formatMessage("app.backToOverview", "Zurück zur Übersicht")}
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={VersionSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="application"
              name="application"
              label={formatMessage("app.application", "Application")}
              options={applicationList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="tenantId"
              name="tenantId"
              label={formatMessage("app.tenantId", "Tenant")}
              options={tenantList.map(option => {
                return {
                  value: option.id,
                  label: option.tenantName,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="createdDate"
              name="createdDate"
              label={formatMessage("app.createdDate", "Create Date")}
              type="datetime-local"
            />
            <Input
              id="endDate"
              name="endDate"
              label={formatMessage("app.endDate", "End Date")}
              type="datetime-local"
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="major"
              name="major"
              type="number"
              label={formatMessage("app.major", "major")}
            />
            <Input
              id="minor"
              name="minor"
              type="number"
              label={formatMessage("app.minor", "minor")}
            />
            <Input
              id="build"
              name="build"
              type="number"
              label={formatMessage("app.build", "build")}
            />
            <Input
              id="revision"
              name="revision"
              type="number"
              label={formatMessage("app.revision", "revision")}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <OldButton id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              {formatMessage("app.cancel", "Cancel")}
            </OldButton>
            <OldButton id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </OldButton>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default injectIntl(VersionsModal);
