import React, { FC, useEffect } from "react";
import { Flex } from "reflexbox";

import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { useSwal } from "@hooks/useSwal";

import { Button, TextButton } from "@icr/react.uicomponents";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { TModalPassword } from "./types";
import { MailServerPasswordSchema } from "./schema";
import { changePasswordMailServerSetting } from "../ducks";
import { useFormatMessage } from "@hooks/formatMessage";

const MailServerSettingsModalPassword: FC<TModalPassword> = ({ closeModal, initialData }) => {
  const formatMessage = useFormatMessage();

  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Password has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    swalConfirmationMessage({
      text: "Do you really want to change this mail server setting password?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Change password",
    }).then(value => {
      if (value) {
        const { id, password } = formData;

        changePasswordMailServerSetting(id, password).then(res => {
          if (res.success) showSuccessMessage("changed");
        });
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column">
        <Form onSubmit={onSubmit} schema={MailServerPasswordSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="password"
              name="password"
              type="password"
              width="50%"
              label={formatMessage("app.password")}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="passwordrepeat"
              name="passwordrepeat"
              type="password"
              width="50%"
              label={formatMessage("app.passwordrepeat", "Repeat password")}
            />
          </Flex>
          <Flex justifyContent="flex-end" mt="30px" width="50%">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default MailServerSettingsModalPassword;
