import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";

import { Text } from "@components/Text";
import { Form } from "@components/Form";
import { Card } from "@components/Card";
import { OldButton } from "@components/Button";
import { ListContainer } from "@components/ListContainer";
import { FormSelect as Select } from "@components/Form/Select";

import { TProps } from "./types";
import { exportDatabase } from "./ducks";
import { DatabaseExportSchema } from "./schema";
import { TDispatch } from "../../../redux/types";
import { fetchTenants } from "../../../actions/tenant";
import { useSwal } from "@hooks/useSwal";

setLocale({
  object: {
    required: "app.requiredField",
  },
});

const DatabaseExport: FC<TProps> = props => {
  const { initialData } = props;
  const [tenantList, setTenantList] = useState([]);
  // Swal
  const { swalAlert, swalConfirmationMessage } = useSwal();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTenants()).then(res => setTenantList(res));
  }, [dispatch]);

  const successfulMessage = (message: string) => {
    swalAlert({
      text: `${message}`,
      type: "success",
      confirmationText: "Ok!",
    });
  };

  const onSubmit = formData => {
    const exportData = {
      ...formData,
      tenantId: formData.tenantId ? [formData.tenantId.value] : [],
    };

    swalConfirmationMessage({
      text: "Do you really want export database?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Export Database",
    }).then(value => {
      if (value) {
        exportDatabase(exportData.tenantId).then(res => {
          if (res.success) successfulMessage("Database exported!");
        });
      }
    });
  };

  return (
    <ListContainer>
      <div className={classNames("main-content-container users")}>
        <div className="content-container">
          <Card>
            <Flex flexDirection="column">
              <Form onSubmit={onSubmit} schema={DatabaseExportSchema} defaultValues={initialData}>
                <Text ml="15px">{formatMessage("app.canExportFullOrTenantSelected")}</Text>
                <Flex mt="30px">
                  <Select
                    id="tenantId"
                    name="tenantId"
                    label={formatMessage("tenant")}
                    options={tenantList?.map(option => ({
                      value: option.id,
                      label: option.tenantName,
                    }))}
                  />
                </Flex>
                <Flex mt="15px" ml="15px">
                  <OldButton id="webServicesModalBtn" type="submit">
                    <span className="d-none d-sm-inline text-uppercase">
                      {formatMessage("app.exportDatabase")}
                    </span>
                  </OldButton>
                </Flex>
              </Form>
            </Flex>
          </Card>
        </div>
      </div>
    </ListContainer>
  );
};

export default DatabaseExport;
