import React, { FC, useEffect, useMemo } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { Subscription } from "rxjs";
import { FormattedMessage } from "react-intl";

import { Form } from "@components/Form";
import { OldButton } from "@components/Button";
import { FormSelect as Select } from "@components/Form/Select";
import { FormCheckbox as Checkbox } from "@components/Form/Checkbox";
import { FormInput as Input } from "@components/Form/Input";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { TenantTranslationsSchema } from "./schema";
import { changeTenantTranslation } from "../ducks";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const TenantTranslationsModal: FC<TProps> = props => {
  const {
    languageList,
    baseTextTranslateIdList,
    translationTypeList,
    moduleList,
    layerList,
    tenantList,
    closeModal,
    initialData,
  } = props;
  const subscriptions$: Subscription = useMemo(() => new Subscription(), []);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    return () => {
      subscriptions$.unsubscribe();
    };
  }, [subscriptions$]);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant translation has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const tenantTranslation = {
      ...formData,
      layer: formData.layer ? formData.layer.value : undefined,
      module: formData.module ? formData.module.value : undefined,
      translationType: formData.translationType ? formData.translationType.value : undefined,
      language: formData.language ? formData.language.value : undefined,
      baseTextTranslateId: formData.baseTextTranslateId
        ? formData.baseTextTranslateId.value
        : undefined,
      tenantId: formData.tenantId ? formData.tenantId.value : undefined,
    };

    swalConfirmationMessage({
      text: "Do you really want to change this tenant translation?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Change Translation",
    }).then(value => {
      if (value) {
        changeTenantTranslation(formData.id, tenantTranslation).then(ret => {
          if (ret.success) showSuccessMessage("changed");
        });
      }
    });
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            <FormattedMessage id="app.backToOverview" defaultMessage="Zurück zur Übersicht" />
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={TenantTranslationsSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input id="translateId" name="translateId" hidden />
            <Input id="originalText" name="originalText" hidden />
            <Select
              isDisabled={true}
              id="tenantId"
              name="tenantId"
              label={<FormattedMessage id="app.tenantId" defaultMessage="Tenant" />}
              options={tenantList.map(option => {
                return {
                  value: option.id,
                  label: option.tenantName,
                };
              })}
            />
            <Select
              isDisabled={true}
              id="layer"
              name="layer"
              label={<FormattedMessage id="app.layer" defaultMessage="Layer" />}
              options={layerList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
            <Select
              isDisabled={true}
              id="module"
              name="module"
              label={<FormattedMessage id="app.module" defaultMessage="Module" />}
              options={moduleList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              isDisabled={true}
              id="translationType"
              name="translationType"
              label={
                <FormattedMessage id="app.translationType" defaultMessage="Translation Type" />
              }
              options={translationTypeList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
            <Select
              isDisabled={true}
              id="language"
              name="language"
              label={<FormattedMessage id="app.language" defaultMessage="Language" />}
              options={languageList
                .filter(data => data.id !== "en-US")
                .map(option => {
                  return {
                    value: option.id,
                    label: option.description,
                  };
                })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              isDisabled={true}
              id="baseTextTranslateId"
              name="baseTextTranslateId"
              label={
                <FormattedMessage
                  id="app.baseTextTranslateId"
                  defaultMessage="Base Text Translate"
                />
              }
              options={baseTextTranslateIdList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              readOnly={true}
              id="translationId"
              name="translationId"
              label={<FormattedMessage id="app.translationId" defaultMessage="Translation Id" />}
            />
            <Checkbox
              id="automaticTranslation"
              name="automaticTranslation"
              label={
                <FormattedMessage
                  id="app.automaticTranslation"
                  defaultMessage="Automatic Translation"
                />
              }
              wrapperProps={{ width: "30%" }}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="textTranslated"
              name="textTranslated"
              label={<FormattedMessage id="app.textTranslated" defaultMessage="Text Translated" />}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <OldButton id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
            </OldButton>
            <OldButton id="ModalBtn_save" type="submit">
              <FormattedMessage id="app.save" defaultMessage="Save" />
            </OldButton>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default TenantTranslationsModal;
