import React, { FC, useEffect, useState } from "react";
import { Flex } from "reflexbox";
import { setLocale } from "yup";

import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";

import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { TProps } from "./types";
import { ObjectRoleSchema } from "./schema";

import { Button, TextButton } from "@icr/react.uicomponents";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { createObjectRole, changeObjectRole } from "../ducks";
import { fetchRoles } from "../../../../actions/role";
import { fetchObjects } from "../../../../actions/object";
import { useDispatch } from "react-redux";
import { TDispatch } from "../../../../redux/types";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const ObjectRolesModal: FC<TProps> = props => {
  const { closeModal, initialData } = props;

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [isEditing, setIsEditing] = useState<boolean>();
  const [rolesList, setRolesList] = useState<Record<string, unknown>[]>();
  const [objectsList, setObjectsList] = useState<Record<string, unknown>[]>();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    dispatch(fetchRoles()).then(ret => setRolesList(ret));
    dispatch(fetchObjects()).then(ret => setObjectsList(ret));
  }, [dispatch]);

  const showSuccessMessage = action => {
    swalAlertWithCallback(
      {
        text: `Object Role has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const objectRole = {
      ...formData,
      objectId: formData.objectId ? formData.objectId.value : undefined,
      roleId: formData.roleId ? formData.roleId.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this object role?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          changeObjectRole(formData.id, objectRole).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      delete objectRole.id;
      swalConfirmationMessage({
        text: "Do you really want to create this object role?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Object Role",
      }).then(value => {
        if (value) {
          createObjectRole(objectRole).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={ObjectRoleSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="objectId"
              name="objectId"
              label={formatMessage("app.object", "Object")}
              options={objectsList?.map(option => {
                return {
                  value: option.id,
                  label: option.objectName,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="roleId"
              name="roleId"
              label={formatMessage("app.role", "Role")}
              options={rolesList?.map(option => {
                return {
                  value: option.id,
                  label: option.roleName,
                };
              })}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default ObjectRolesModal;
