import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { Form } from "@components/Form";
import { Checkbox } from "@components/Checkbox";
import { Card } from "@components/Card";
import { OldButton } from "@components/Button";
import { ListContainer } from "@components/ListContainer";
import { FormInput as Input } from "@components/Form/Input";

import { useFormatMessage } from "@hooks/formatMessage";

import { DefaultVariableSchema } from "./schema";
import { saveDefaultVariables } from "./ducks";
import { fetchDefaultVariables } from "../../../actions/defaultVariable";
import { useSwal } from "@hooks/useSwal";

setLocale({
  string: {
    required: "app.requiredField",
  },
});

const DefaultVariable: FC = () => {
  const [initialData, setInitialData] = useState(undefined);
  const [dataLoaded, setDataLoaded] = useState(false);
  // Swal
  const { swalAlert, swalConfirmationMessage } = useSwal();
  const dispatch = useDispatch();
  const formatMessage = useFormatMessage();

  useEffect(() => {
    if (!dataLoaded) {
      dispatch(fetchDefaultVariables()).then(res => {
        setInitialData(res);
        setDataLoaded(!dataLoaded);
      });
    }
  }, [dataLoaded, dispatch]);

  const successfulMessage = (message: string) => {
    swalAlert({
      text: `${message}`,
      type: "success",
      confirmationText: "Ok!",
    });
  };

  const onSubmit = formData => {
    const exportData = {
      ...formData,
    };

    swalConfirmationMessage({
      text: "Do you really want change default variable values?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Export Database",
    }).then(value => {
      if (value) {
        saveDefaultVariables(exportData).then(res => {
          if (res.success) successfulMessage("Variables changed!");
        });
      }
    });
  };

  return (
    <ListContainer>
      <div className={classNames("main-content-container users")}>
        <div className="content-container">
          <Card>
            <Flex flexDirection="column">
              {dataLoaded && (
                <Form
                  onSubmit={onSubmit}
                  schema={DefaultVariableSchema}
                  defaultValues={initialData}
                >
                  <Flex>
                    <Input
                      id="passwordSalt"
                      name="passwordSalt"
                      type="string"
                      label={formatMessage("app.passwordSalt", "Password Salt")}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="secretKeyForOtpNetHotp"
                      name="secretKeyForOtpNetHotp"
                      type="string"
                      label={formatMessage(
                        "app.secretKeyForOtpNetHotp",
                        "Secret Key For OtpNet Hotp"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="baseUrlPostalLetter"
                      name="baseUrlPostalLetter"
                      type="string"
                      label={formatMessage(
                        "app.baseUrlPostalLetter",
                        "Base Url For Postal Letter API"
                      )}
                    />
                    <Checkbox
                      id="printInColorPostalLetter"
                      name="printInColorPostalLetter"
                      wrapperProps={{ width: "25%", flex: 1 }}
                      label={formatMessage("app.printInColorPostalLetter", "Print in Color")}
                    />
                    <Checkbox
                      id="priorityMailPostalLetter"
                      name="priorityMailPostalLetter"
                      wrapperProps={{ width: "25%", flex: 1 }}
                      label={formatMessage(
                        "app.priorityMailPostalLetter",
                        "Priority Mail Postal Letter"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="tokenPostalLetter"
                      name="tokenPostalLetter"
                      type="string"
                      label={formatMessage("app.tokenPostalLetter", "Token For Postal Letter")}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="sms_AwsAccessKeyId"
                      name="sms_AwsAccessKeyId"
                      type="string"
                      label={formatMessage(
                        "app.sms_AwsAccessKeyId",
                        "Aws Access Key Id For SMS API"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="sms_AwsSecretAccessKey"
                      name="sms_AwsSecretAccessKey"
                      type="string"
                      label={formatMessage(
                        "app.sms_AwsSecretAccessKey",
                        "Aws Secret Access Key For SMS API"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="translation_AwsAccessKeyId"
                      name="translation_AwsAccessKeyId"
                      type="string"
                      label={formatMessage(
                        "app.translation_AwsAccessKeyId",
                        "Aws Access Key Id For Translation API"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="translation_AwsSecretAccessKey"
                      name="translation_AwsSecretAccessKey"
                      type="string"
                      label={formatMessage(
                        "app.translation_AwsSecretAccessKey",
                        "Aws Secret Access Key For Translation API"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="secretForConnection"
                      name="secretForConnection"
                      type="string"
                      label={formatMessage("app.secretForConnection", "Secret for connection")}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="secretForMailServerSetting"
                      name="secretForMailServerSetting"
                      type="string"
                      label={formatMessage(
                        "app.secretForMailServerSetting",
                        "Secret for mail server setting"
                      )}
                    />
                  </Flex>
                  <Flex mt="30px">
                    <Input
                      id="secretForWebService"
                      name="secretForWebService"
                      type="string"
                      label={formatMessage("app.secretForWebService", "Secret for web service")}
                    />
                  </Flex>
                  <Flex mt="15px" ml="15px">
                    <OldButton id="ModalBtnSave" type="submit">
                      <span className="d-none d-sm-inline text-uppercase">
                        {formatMessage("app.updateDefaultVariables")}
                      </span>
                    </OldButton>
                  </Flex>
                </Form>
              )}
            </Flex>
          </Card>
        </div>
      </div>
    </ListContainer>
  );
};

export default DefaultVariable;
