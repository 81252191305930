import { Dispatch } from "redux";
import * as constants from "../constants";

import http from "@api/http";
import { TResponse } from "@api/types";
import { deserialize } from "@api/jsonApiParser";

export const fetchObjects = () => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });
  return await http.get("Object/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: constants.LOADING, payload: false });
    return response.data.map(user => deserialize(user));
  });
};
