import React, { FC, useEffect, useState } from "react";

import Users from "./Users/Users";
import Roles from "./Roles/Roles";
import { Link, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";

import "../../styles/index.scss";
import "./UserBase.scss";

import { Header, HeaderTab, ListContainer } from "@icr/react.uicomponents";
import { useFormatMessage } from "@hooks/formatMessage";
import { useDispatch, useSelector } from "react-redux";
import { TRootState } from "redux/types";
import { logOut } from "../../shared/state/AuthenticateActions";

const tabs = [
  { path: "users", renderComponent: props => <Users {...props} /> },
  { path: "roles", renderComponent: props => <Roles {...props} /> },
];

const UserBase: FC = () => {
  const [currentTab, setCurrentTab] = useState("");
  const { path, url } = useRouteMatch();
  const formatMessage = useFormatMessage();
  const history = useHistory();
  const location = useLocation();
  const state = location.state as Record<string, string>;

  const userData = useSelector(({ LoginReducer }: TRootState) => LoginReducer.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (state) {
      setCurrentTab(state.currentTab);
    }
  }, [state]);

  return (
    <ListContainer>
      <Header
        title="Users"
        changePasswordLabel={formatMessage("app.changePassword", "Change Password")}
        changeLanguage={null}
        changePassword={null}
        languages={null}
        logoutLabel={formatMessage("app.logout", "Sign Out")}
        userData={userData}
        logOut={() => dispatch(logOut())}
      >
        {tabs.map(tab => (
          <Link to={{ pathname: `${url}/${tab.path}`, state: { currentTab: tab.path } }}>
            <HeaderTab active={currentTab === tab.path} title={tab.path} />
          </Link>
        ))}
      </Header>
      <Switch>
        {tabs.map(tab => (
          <Route path={`${path}/${tab.path}`}>{tab.renderComponent({ location, history })}</Route>
        ))}
      </Switch>
    </ListContainer>
  );
};

export default UserBase;
