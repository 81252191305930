import React, { FC, useEffect, useMemo, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { Subscription } from "rxjs";
import { FormattedMessage } from "react-intl";

import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { OldButton } from "@components/Button";
import { FormSelect as Select } from "@components/Form/Select";
import { Button as OldButton, EButtonType } from "@components/OldButton";
import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { TenantMappingsSchema } from "./schema";
import { changeTenantMapping, createTenantMapping } from "../ducks";
import { ReactComponent as IconArrowRight } from "../../../../assets/icons/arrow-right.svg";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const TenantMappingsModal: FC<TProps> = props => {
  const { tenantList, tenantMappingTypeList, closeModal, initialData } = props;
  const subscriptions$: Subscription = useMemo(() => new Subscription(), []);
  const [isEditing, setIsEditing] = useState<boolean>();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    return () => {
      subscriptions$.unsubscribe();
    };
  }, [subscriptions$]);

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Mapping has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const tenantMapping = {
      ...formData,
      tenantId: formData.tenantId ? formData.tenantId.value : undefined,
      type: formData.type ? formData.type.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this tenant mapping?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Change Tenant Mapping",
      }).then(value => {
        if (value) {
          changeTenantMapping(formData.id, tenantMapping).then(ret => {
            if (ret.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this tenant mapping?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save Tenant Mapping",
      }).then(value => {
        if (value) {
          delete tenantMapping.id;
          createTenantMapping(tenantMapping).then(ret => {
            if (ret.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <OldButton
            className="primary"
            onClick={closeModal}
            to={null}
            buttonType={EButtonType.ButtonText}
          >
            <div className="iconWrapper arrow left d-inline-block mr-3 small">
              <IconArrowRight />
            </div>
            <FormattedMessage id="app.backToOverview" defaultMessage="Zurück zur Übersicht" />
          </OldButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={TenantMappingsSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="tenantId"
              name="tenantId"
              label={<FormattedMessage id="app.tenantId" defaultMessage="Tenant" />}
              options={tenantList.map(option => {
                return {
                  value: option.id,
                  label: option.tenantName,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="type"
              name="type"
              label={<FormattedMessage id="app.type" defaultMessage="Type" />}
              options={tenantMappingTypeList.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Input
              id="value"
              name="value"
              label={<FormattedMessage id="app.value" defaultMessage="Value" />}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <OldButton id="ModalBtn_cancel" textButton mr="10px" onClick={closeModal}>
              <FormattedMessage id="app.cancel" defaultMessage="Cancel" />
            </OldButton>
            <OldButton id="ModalBtn_save" type="submit">
              <FormattedMessage id="app.save" defaultMessage="Save" />
            </OldButton>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default TenantMappingsModal;
