import { Dispatch } from "redux";
import { Observable } from "rxjs";

import http from "@api/http";
import { TResponse } from "@api/types";
import { ApiService } from "@api/apiHandler";
import { buildFilterQueryString, deserialize } from "@api/jsonApiParser";

import * as constants from "../../../constants";
import { ACTION } from "../../../shared/models/action";

export const FETCHED_LOGONLOGS = "FETCHED_LOGONLOGS";
export const SET_FILTERING_CRITERIA_LOGONLOGS = "SET_FILTERING_CRITERIA_LOGONLOGS";
export const SET_ORDER_CRITERIA_LOGONLOGS = "SET_ORDER_CRITERIA_LOGONLOGS";
export const SET_LOGONLOGS_PAGE_NO = "SET_LOGONLOGS_PAGE_NO";
export const INCREMENT_LOGONLOGS_PAGE_NO = "INCREMENT_LOGONLOGS_PAGE_NO";

export interface LogonLogsState {
  logonLogs: any;
  logonLogsPageNo: any;
  moreLogonLogs: any;
  filter: any;
  order: Array<string>;
}

const initialState = {
  logonLogs: [],
  logonLogsPageNo: 1,
  moreLogonLogs: true,
  filter: {},
  order: [],
};

export const LogonLogReducer = (state: LogonLogsState = initialState, action: any) => {
  const orderedLogonLogs: Array<string> = [];

  switch (action.type) {
    case SET_LOGONLOGS_PAGE_NO: {
      // console.log("reducer loading", action);
      const moreLogonLogs = !(
        state.logonLogs.length <
        (state.logonLogsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, logonLogsPageNo: action.payload, moreLogonLogs: moreLogonLogs };
    }

    case FETCHED_LOGONLOGS: {
      let logonLogs = [];
      const moreLogonLogs = !(action.payload.length < constants.PAGINATION_SIZE);
      if (state.logonLogsPageNo === 1 || action.page === 1) {
        logonLogs = [...action.payload];
      } else {
        logonLogs = [...state.logonLogs, ...action.payload];
      }
      return { ...state, logonLogs: logonLogs, moreLogonLogs: moreLogonLogs };
    }

    case SET_FILTERING_CRITERIA_LOGONLOGS: {
      if (!action.payload) {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload };
      }
    }

    case INCREMENT_LOGONLOGS_PAGE_NO: {
      return {
        ...state,
        logonLogsPageNo: state.logonLogsPageNo + 1,
      };
    }

    // Adds, removes or sets ordering criteria
    case SET_ORDER_CRITERIA_LOGONLOGS: {
      switch (action.payload.method) {
        case "add":
          if (state.order.includes(action.payload.value)) {
            // if order for a field is set, remove it
            return {
              ...state,
              logonLogsPageNo: 1,
              order: [...state.order.filter(order => order !== action.payload.value)],
            };
          } else {
            return {
              ...state,
              logonLogsPageNo: 1,
              order: [...state.order.concat(action.payload.value)],
            };
          }
        case "remove":
          return {
            ...state,
            logonLogsPageNo: 1,
            order: [...state.order.filter(order => order !== action.payload.value)],
          };
        case "set":
          for (const key in action.payload.value) {
            if (action.payload.value[key] !== "") {
              orderedLogonLogs.push(key + action.payload.value[key]);
            }
          }
          return { ...state, logonLogsPageNo: 1, order: orderedLogonLogs };
        case "reset":
          return { ...state, logonLogsPageNo: 1, order: [] };
      }
    }
  }

  return state;
};

export const setLogonLogsPageNo = (payload: number): ACTION => ({
  type: SET_LOGONLOGS_PAGE_NO,
  payload,
});

export const setLogonLogsOrderCriteria = (payload: any): ACTION => ({
  type: SET_ORDER_CRITERIA_LOGONLOGS,
  payload,
});

export const setLogonLogsFilterCriteria = (payload: any): ACTION => ({
  type: SET_FILTERING_CRITERIA_LOGONLOGS,
  payload,
});

export const fetchLogonLogsList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    const query = `LogonLogEntry/LogonLogEntryPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse<Record<string, unknown>[]>) => {
        dispatch({
          type: FETCHED_LOGONLOGS,
          payload: (response.data as Record<string, unknown>[]).map(logonlogs =>
            deserialize(logonlogs)
          ),
          page: page,
        });
        dispatch({ type: constants.LOADING, payload: false });
      });
  };
};

export const deleteLogonLog = (id: string): Observable<any> => {
  return ApiService.delete("LogonLogEntry/" + id);
};

export const getLogonLogById = (id: string): Observable<any> => {
  return ApiService.get("LogonLogEntry/" + id);
};

export const incrementLogonLogsPageNo = () => ({
  type: INCREMENT_LOGONLOGS_PAGE_NO,
});
