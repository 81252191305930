import * as constants from "../constants";
import { TDispatch } from "../redux/types";

import http from "@api/http";
import { deserialize } from "@api/jsonApiParser";
import { TResponse } from "@api/types";

export const fetchWebServices = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http.get("WebService/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: constants.LOADING, payload: false });
    return response.data.map(user => deserialize(user));
  });
};
