import React, { FC, useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { deserialize } from "@api/jsonApiParser";
import TranslationsModal from "./Modal/TranslationModal";
import { getParameterByName } from "../../../utils/toSearchParams";

import {
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  Checkbox,
  Select,
  Input,
  Filter,
  AddButton,
  TrashIcon,
} from "@icr/react.uicomponents";

import {
  deleteTranslation,
  fetchTranslationList,
  getTranslationById,
  incrementTranslationsPageNo,
  setTranslationsPageNo,
} from "./ducks";
import {
  fetchBaseTextTranslates,
  fetchLayers,
  fetchModules,
  fetchTranslationTypes,
} from "../../../actions/enumerator";
import { fetchLanguages } from "../../../reducers/enumerator";
import "../../../styles/index.scss";
import { TDispatch, TRootState } from "../../../redux/types";
import { useSwal } from "@hooks/useSwal";

import { useFormatMessage } from "@hooks/formatMessage";

enum formMode {
  edit = "edit",
}

const Translations: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTranslationForm, setAddTranslationForm] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [baseTextTranslateIdList, setBaseTextTranslateIdList] = useState([]);
  const [translationTypeList, setTranslationTypeList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [layerList, setLayerList] = useState([]);

  const [genericTextFilter, setGenericTextFilter] = useState<string>("");
  const [layerFilter, setLayerFilter] = useState<string>("");
  const [moduleFilter, setModuleFilter] = useState<string>("");
  const [translationTypeFilter, setTranslationTypeFilter] = useState<string>("");
  const [languageFilter, setLanguageFilter] = useState<string>("");
  const [textTranslatedFilter, setTextTranslatedFilter] = useState<string>("");
  const [listNotTranslated, setListNotTranslated] = useState<boolean>(false);
  const [translationIdFilter, setTranslationIdFilter] = useState<string>("");
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const history = useHistory();
  const location = useLocation();
  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { translations, moreTranslations, translationsPageNo } = useSelector(
    ({
      TranslationsReducer: { translations, moreTranslations, translationsPageNo },
    }: TRootState) => ({
      translations,
      moreTranslations,
      translationsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTranslationList()).then(() => dispatch(incrementTranslationsPageNo()));
    dispatch(fetchLanguages()).then(res => setLanguageList(res));
    dispatch(fetchBaseTextTranslates()).then(res => setBaseTextTranslateIdList(res));
    dispatch(fetchTranslationTypes()).then(res => setTranslationTypeList(res));
    dispatch(fetchModules()).then(res => setModuleList(res));
    dispatch(fetchLayers()).then(res => setLayerList(res));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTranslations) {
      dispatch(
        fetchTranslationList(translationsPageNo, 10, {
          GenericText: genericTextFilter,
          Layer: layerFilter,
          Module: moduleFilter,
          TranslationType: translationTypeFilter,
          Language: languageFilter,
          TextTranslated: textTranslatedFilter,
          ListNotTranslated: listNotTranslated,
          TranslationId: translationIdFilter,
        })
      ).then(() => dispatch(incrementTranslationsPageNo()));
    }
  };

  const filterTranslations = () => {
    dispatch(setTranslationsPageNo(1));
    dispatch(
      fetchTranslationList(1, 10, {
        GenericText: genericTextFilter,
        Layer: layerFilter,
        Module: moduleFilter,
        TranslationType: translationTypeFilter,
        Language: languageFilter,
        TextTranslated: textTranslatedFilter,
        ListNotTranslated: listNotTranslated,
        TranslationId: translationIdFilter,
      })
    );
  };

  const clearFilters = () => {
    setGenericTextFilter("");
    setLayerFilter("");
    setModuleFilter("");
    setTranslationTypeFilter("");
    setLanguageFilter("");
    setTextTranslatedFilter("");
    setListNotTranslated(false);
    setTranslationIdFilter("");

    dispatch(setTranslationsPageNo(1));
    dispatch(fetchTranslationList());
  };

  const prepareLanguageOptions = useCallback(() => {
    if (languageList) {
      return languageList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [languageList]);

  const prepareModuleOptions = useCallback(() => {
    if (moduleList) {
      return moduleList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [moduleList]);

  const prepareTranslationTypeOptions = useCallback(() => {
    if (translationTypeList) {
      return translationTypeList.map(item => ({
        value: item.description,
        id: item.id,
      }));
    }
  }, [translationTypeList]);

  const prepareLayerOptions = useCallback(() => {
    if (layerList) {
      return layerList.map(item => ({
        value: item.id,
        label: item.description,
      }));
    }
  }, [layerList]);

  const validateValue = (value, arr) => {
    return value ? arr?.find(item => item.value === value) : "";
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({
      search: "",
    });
    filterTranslations();
  };

  const openModal = (content, message?, translation?) => {
    getTranslationById(translation.id).then(res => {
      if (res.success) {
        setAddTranslationForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTranslations = () => {
    history.push({
      search: "",
    });
    filterTranslations();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Translation has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTranslations
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this translation?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove translation",
    }).then(value => {
      if (value) {
        deleteTranslation(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  const renderTranslationsSideBar = () => {
    const languageOptions = prepareLanguageOptions();
    const layerOptions = prepareLayerOptions();
    const moduleOptions = prepareModuleOptions();
    const translationTypeOptions = prepareTranslationTypeOptions();

    return (
      <Filter
        id="filterArea"
        searchButtonText={formatMessage("app.search", "Search")}
        onClickSearchButton={filterTranslations}
        clearButtonText={formatMessage("app.clear", "Clear")}
        onClickClearButton={clearFilters}
        open={true}
      >
        <Input
          id="dynamicSearch"
          label="Dynamic Filter"
          placeholder={formatMessage("app.anyfield", "Any Field")}
          value={genericTextFilter}
          onChange={e => setGenericTextFilter(e.target.value)}
        />

        <div className="breaker mb-5" />

        <Select
          id="layerSearch"
          label={formatMessage("app.layer", "Layer")}
          wrapperProps={{ marginTop: "10px" }}
          options={layerOptions}
          value={validateValue(layerFilter, layerOptions)}
          onChange={e => setLayerFilter(e.value)}
        />
        <Select
          id="moduleSearch"
          label={formatMessage("app.module", "Module")}
          wrapperProps={{ marginTop: "10px" }}
          options={moduleOptions}
          value={validateValue(moduleFilter, moduleOptions)}
          onChange={e => setModuleFilter(e.value)}
        />
        <Select
          id="translationTypeSearch"
          label={formatMessage("app.translationType", "Translation Type")}
          wrapperProps={{ marginTop: "10px" }}
          options={translationTypeOptions}
          value={validateValue(translationTypeFilter, translationTypeOptions)}
          onChange={e => setTranslationTypeFilter(e.value)}
        />
        <Select
          id="languageSearch"
          label={formatMessage("app.language", "Language")}
          wrapperProps={{ marginTop: "10px" }}
          options={languageOptions}
          value={validateValue(languageFilter, languageOptions)}
          onChange={e => setLanguageFilter(e.value)}
        />
        <Input
          id="translationIdSearch"
          placeholder={formatMessage("app.translationId", "Translation Id")}
          wrapperProps={{ marginTop: "10px" }}
          value={translationIdFilter}
          onChange={e => setTranslationIdFilter(e.target.value)}
        />
        <Input
          id="textTranslatedSearch"
          placeholder={formatMessage("app.textTranslated", "Text translated")}
          wrapperProps={{ marginTop: "10px" }}
          value={textTranslatedFilter}
          onChange={e => setTextTranslatedFilter(e.target.value)}
        />
        <Checkbox
          id="listNotTranslatedSearch"
          name="listNotTranslatedSearch"
          label={formatMessage("app.listNotTranslated", "List not translated")}
          wrapperProps={{ marginTop: "10px" }}
          checked={listNotTranslated}
          setChecked={value => setListNotTranslated(value)}
        />
      </Filter>
    );
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {modalIsOpen && (
          <TranslationsModal
            closeModal={closeModal}
            initialData={addTranslationForm}
            languageList={languageList}
            baseTextTranslateIdList={baseTextTranslateIdList}
            translationTypeList={translationTypeList}
            moduleList={moduleList}
            layerList={layerList}
          />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTranslationBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewtranslation", "Add Translation")}
            onClick={() => {
              setAddTranslationForm("");
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={translations}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.layerName", "Layer")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.moduleName", "Module")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.languageCodeIso", "Language")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.originalText", "Text")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.textTranslated", "Translated")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"translationRow" + index}
                id={item.id as string}
                expandable={true}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.layerName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.moduleName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.languageCodeIso}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {item.originalText}
                      </Text>
                    </ListColumn>
                    <ListColumn>
                      <Text
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {" "}
                        {item.textTranslated}
                      </Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Translation"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Translation"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
                more={
                  <Flex width="100%" mb="40px">
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        {formatMessage("app.translationTypeName", "Translation Type")}
                      </Text>
                      <Text mt="10px">{item.translationTypeName}</Text>
                    </ListColumn>
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        {formatMessage("app.translationId", "Translation Id")}
                      </Text>
                      <Text mt="10px">{item.translationId}</Text>
                    </ListColumn>
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        {formatMessage("app.automaticTranslation", "Automatic Translation")}
                      </Text>
                      <Checkbox
                        id="TableAutomaticTranslationInput"
                        checked={item.automaticTranslation as boolean}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        {renderTranslationsSideBar()}
      </Flex>
    </ListContainer>
  );
};

export default Translations;
