import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { useHistory, useLocation } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { deserialize } from "@api/jsonApiParser";
import { useFormatMessage } from "@hooks/formatMessage";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  EditIcon,
  TrashIcon,
  ListContainer,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { useSwal } from "@hooks/useSwal";

import "../../../styles/index.scss";
import { TDispatch, TRootState } from "../../../redux/types";
import TenantSettingsModal from "./Modal/TenantSettingModal";
import { getParameterByName } from "../../../utils/toSearchParams";
import {
  setTenantSettingsPageNo,
  fetchTenantSettingList,
  deleteTenantSetting,
  getTenantSettingById,
  incrementTenantSettingPageNo,
} from "./ducks";

enum formMode {
  edit = "edit",
}

const TenantSettings: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const [addTenantSettingForm, setAddTenantSettingForm] = useState(undefined);
  const [genericTextFilter, setGenericTextFilter] = useState<string>(null);

  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const location = useLocation();
  const history = useHistory();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { tenantSettings, moreTenantSettings, tenantSettingsPageNo } = useSelector(
    ({
      TenantSettingsReducer: { tenantSettings, moreTenantSettings, tenantSettingsPageNo },
    }: TRootState) => ({
      tenantSettings,
      moreTenantSettings,
      tenantSettingsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchTenantSettingList()).then(() => incrementTenantSettingPageNo());
  }, [dispatch]);

  const handleScroll = () => {
    if (moreTenantSettings) {
      dispatch(
        fetchTenantSettingList(tenantSettingsPageNo, 10, { GenericText: genericTextFilter })
      ).then(() => dispatch(incrementTenantSettingPageNo()));
    }
  };

  const filterTenantSettings = () => {
    dispatch(setTenantSettingsPageNo(1));
    dispatch(fetchTenantSettingList(1, 10, { GenericText: genericTextFilter }));
  };

  const clearFilters = () => {
    setGenericTextFilter(null);

    dispatch(setTenantSettingsPageNo(1));
    dispatch(fetchTenantSettingList());
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ search: "" });
    filterTenantSettings();
  };

  const openModal = (content, message?, tenantSetting?) => {
    getTenantSettingById(tenantSetting.id).then(res => {
      if (res.success) {
        setAddTenantSettingForm(deserialize(res.data));
        setModalIsOpen(true);
        setModalContent(content);
      }
    });
  };

  const saveHistoryAndFilterTenantSettings = () => {
    history.push({
      search: "",
    });
    filterTenantSettings();
  };

  const showSuccessMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: `Tenant Setting has been ${message}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      saveHistoryAndFilterTenantSettings
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "You really want to delete this tenant setting?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove tenant setting",
    }).then(value => {
      if (value) {
        deleteTenantSetting(id).then(res => {
          if (res.success) showSuccessMessage("deleted");
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className="large-modal"
        overlayClassName="large-modal-overlay"
      >
        {modalIsOpen && (
          <TenantSettingsModal closeModal={closeModal} initialData={addTenantSettingForm} />
        )}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addTenantSettingBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addSetting")}
            onClick={() => {
              setAddTenantSettingForm({});
              setModalIsOpen(true);
            }}
          />

          <ScrollableList
            items={tenantSettings}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("tenant")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.application")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.type")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.file")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"tenantSettingRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.tenantName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.applicationName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.typeName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.fileName}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Setting"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Setting"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterTenantSettings}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default TenantSettings;
