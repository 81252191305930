import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { Flex } from "reflexbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  TrashIcon,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import { deserialize } from "@api/jsonApiParser";
import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";
import { ReactComponent as IconKey } from "@assets/icons/key-temp.svg";

import {
  setUsersPageNo,
  fetchUsersList,
  deleteUser,
  getUserById,
  incrementUsersPageNo,
  setFormUser,
} from "./ducks";
import UsersModal from "./Modal/UsersModal";
import { TDispatch, TRootState } from "../../../redux/types";
import UsersModalPassword from "./ModalPassword/UsersModalPassword";

import "../../../styles/index.scss";
import "./Users.scss";
import { fetchRolesByUserId } from "@features/User/Roles/ducks";

enum formMode {
  edit = "edit",
  password = "password",
}

const Users: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);

  const [genericTextFilter, setGenericTextFilter] = useState<string>(null);
  const [usernameFilter, setUsernameFilter] = useState<string>(null);
  const [firstnameFilter, setFirstnameFilter] = useState<string>(null);
  const [lastnameFilter, setLastnameFilter] = useState<string>(null);

  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { usersList, moreUsers, usersPageNo } = useSelector(
    ({ UsersReducer: { users, moreUsers, usersPageNo } }: TRootState) => ({
      usersList: users,
      moreUsers,
      usersPageNo,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchUsersList()).then(() => dispatch(incrementUsersPageNo()));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreUsers) {
      setShowMoreSpinner(true);
      dispatch(
        fetchUsersList(usersPageNo, 10, {
          GenericText: genericTextFilter,
          Username: usernameFilter,
          Firstname: firstnameFilter,
          Lastname: lastnameFilter,
        })
      ).then(() => {
        setShowMoreSpinner(false);
        dispatch(incrementUsersPageNo());
      });
    }
  };

  const filterUsers = () => {
    dispatch(setUsersPageNo(1));
    dispatch(
      fetchUsersList(1, 10, {
        GenericText: genericTextFilter,
        Username: usernameFilter,
        Firstname: firstnameFilter,
        Lastname: lastnameFilter,
      })
    );
  };

  const clearFilters = () => {
    setGenericTextFilter(null);
    setUsernameFilter(null);
    setFirstnameFilter(null);
    setLastnameFilter(null);

    dispatch(setUsersPageNo(1));
    dispatch(fetchUsersList(1, 10));
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getUserById(entry.id).then(ret => {
        if (ret) {
          let user = deserialize(ret.data);

          user.birthDate = new Date(user.birthDate);

          fetchRolesByUserId(entry.id).then(roles => {
            // Build roles to show on form if user have any roles
            if (roles && roles.length > 0) {
              roles.forEach(userRole => {
                user = {
                  ...user,
                  [userRole.application +
                  (userRole.applicationName as string).charAt(0).toLowerCase() +
                  (userRole.applicationName as string).slice(1)]: {
                    label: userRole.roleName,
                    value: userRole.roleId,
                  },
                };
              });
            }

            dispatch(setFormUser(user));
            setModalIsOpen(true);
            setModalContent(content);
          });
        }
      });
    } else {
      dispatch(setFormUser({ id: entry.id }));
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterUsers = () => filterUsers();

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterUsers();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `User has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this user?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove user",
    }).then(value => {
      if (value) {
        deleteUser(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterUsers);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen ? (
          modalContent !== formMode.password ? (
            <UsersModal closeModal={closeModal} />
          ) : (
            <UsersModalPassword closeModal={closeModal} />
          )
        ) : null}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addUserBtn"
            p="0 32px"
            m="32px 0 0"
            label={formatMessage("app.addnewuser", "Add User")}
            onClick={() => {
              dispatch(setFormUser({}));
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={usersList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.username", "Username")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.prename", "First name")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.lastname", "Last name")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.company", "Company")}</Text>
                </ListColumn>
                <ListColumn />
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"userRow" + index}
                id={item.id as string}
                expandable={true}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.username}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.firstName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.lastName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.companyName}</Text>
                    </ListColumn>
                    <ListColumn icon>
                      {" "}
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit User"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <IconKey
                        id={"changePasswordEntry" + item.id}
                        title="Edit Password"
                        onClick={() => openModal(formMode.password, "", item)}
                      />{" "}
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete User"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
                more={
                  <Flex width="100%" mb="40px">
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        Language
                      </Text>
                      <Text mt="10px">{item.languageName}</Text>
                    </ListColumn>
                    <ListColumn direction="column">
                      <Text color="#969899" font="medium" fontSize="small">
                        Company
                      </Text>
                      <Text mt="10px">{item.companyName}</Text>
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Flex>
          <Filter
            id="filterArea"
            searchButtonText={formatMessage("app.search", "Search")}
            onClickSearchButton={filterUsers}
            clearButtonText={formatMessage("app.clear", "Clear")}
            onClickClearButton={clearFilters}
            open={true}
          >
            <Input
              id="dynamicSearch"
              label="Dynamic Filter"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={genericTextFilter}
              onChange={e => setGenericTextFilter(e.target.value)}
            />

            <div className="breaker mb-5" />

            <Input
              id="usernameSearch"
              placeholder={formatMessage("app.username", "Username")}
              value={usernameFilter}
              onChange={e => setUsernameFilter(e.target.value)}
            />
            <Input
              id="firstnameSearch"
              placeholder={formatMessage("app.firstname", "Firstname")}
              value={firstnameFilter}
              onChange={e => setFirstnameFilter(e.target.value)}
            />
            <Input
              id="lastnameSearch"
              placeholder={formatMessage("app.lastname", "Lastname")}
              value={lastnameFilter}
              onChange={e => setLastnameFilter(e.target.value)}
            />
          </Filter>
        </Flex>
      </Flex>
    </ListContainer>
  );
};

export default Users;
