import React, { FC, useEffect, useState } from "react";
import dateFormat from "dateformat";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { useFormatMessage } from "@hooks/formatMessage";

import {
  ScrollableList,
  ListColumn,
  ExpandableRow,
  Text,
  ListContainer,
  Input,
  Filter,
} from "@icr/react.uicomponents";

import {
  fetchLogonLogsList,
  incrementLogonLogsPageNo,
  // setLogonLogsOrderCriteria,
  setLogonLogsPageNo,
} from "./ducks";
// import { orderToggleEnum, toggleOrder } from "../../../shared/models/OrderToggleEnum";
import { TDispatch, TRootState } from "../../../redux/types";
import "../../../styles/index.scss";
import { Flex } from "reflexbox";

const LogonLog: FC = () => {
  // const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [genericTextFilter, setGenericTextFilter] = useState<string>(null);
  // const [logonLogListOrder, setLogonLogListOrder] = useState({
  //   logonDate: orderToggleEnum.Off,
  //   logoutDate: orderToggleEnum.Off,
  // });

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();

  const { logonLogsPageNo, logonLogs, moreLogonLogs } = useSelector(
    ({ LogonLogReducer: { logonLogsPageNo, logonLogs, moreLogonLogs } }: TRootState) => ({
      logonLogsPageNo,
      logonLogs,
      moreLogonLogs,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchLogonLogsList()).then(() => dispatch(incrementLogonLogsPageNo()));
  }, [dispatch]);

  const handleScroll = () => {
    if (moreLogonLogs) {
      dispatch(
        fetchLogonLogsList(logonLogsPageNo, 10, {
          GenericText: genericTextFilter,
        })
      ).then(() => dispatch(incrementLogonLogsPageNo()));
    }
  };

  const filterLogonLogs = () => {
    dispatch(setLogonLogsPageNo(1));
    dispatch(
      fetchLogonLogsList(1, 10, {
        GenericText: genericTextFilter,
      })
    );
  };

  const clearLogonLogsFilters = () => {
    setGenericTextFilter("");
    dispatch(setLogonLogsPageNo(1));
    dispatch(fetchLogonLogsList(1, 10));
  };

  // const orderArrow = order => {
  //   switch (order) {
  //     case orderToggleEnum.Ascending:
  //       return <IconArrowUp />;
  //     case orderToggleEnum.Descending:
  //       return <IconArrowDown />;
  //     case orderToggleEnum.Off:
  //       return <IconArrowUpDown />;
  //   }
  // };

  // const setOrderLogonLog = name => {
  //   setLogonLogListOrder(prev => ({ ...prev, [name]: toggleOrder(logonLogListOrder[name]) }));
  //   dispatch(
  //     setLogonLogsOrderCriteria({
  //       method: "set",
  //       value: logonLogListOrder,
  //     })
  //   );
  //   dispatch(fetchLogonLogsList());
  // };

  return (
    <ListContainer>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <ScrollableList
            items={logonLogs}
            onLoadMore={handleScroll}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.username", "Username")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.logonResult", "Logon Result")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.logonDate", "Logon date")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.logoutDate", "Logout date")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.clientIP", "IP Address")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"logonRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold">{item.username}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{item.logonResultName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text>{dateFormat(item.logonDate, "yyyy/mm/dd HH:mm:ss")}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {dateFormat(item.logoutDate, "yyyy/mm/dd HH:mm:ss")}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.clientIpAddress}</Text>
                    </ListColumn>
                    <ListColumn icon></ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Flex>
          <Filter
            id="filterArea"
            searchButtonText={formatMessage("app.search", "Search")}
            onClickSearchButton={filterLogonLogs}
            clearButtonText={formatMessage("app.clear", "Clear")}
            onClickClearButton={clearLogonLogsFilters}
            open={true}
          >
            <Input
              id="dynamicSearch"
              label="Dynamic Filter"
              placeholder={formatMessage("app.anyfield", "Any Field")}
              value={genericTextFilter}
              onChange={e => setGenericTextFilter(e.target.value)}
            />
          </Filter>
        </Flex>
      </Flex>
    </ListContainer>
  );
};

export default LogonLog;
