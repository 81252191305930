import { Dispatch } from "redux";
import { Cookies } from "react-cookie";
import { push } from "connected-react-router";
import http from "@api/http";
import { store } from "@redux/store";

import { ACTION } from "../models/action";
import { setLoading } from "../../actions";
import { LOG_OUT, LOGIN, LOGIN_IS_LOADING, LOGIN_SUCCESS } from "./AuthenticateReducers";
import { TDispatch } from "@redux/types";

const cookies = new Cookies();

export const loginAct = (payload: any): ACTION => ({
  type: LOGIN,
  payload,
});

export const loginSuccess = (payload: any): ACTION => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const doLogin = (payload, history) => async (
  dispatch: Dispatch
): Promise<Record<string, unknown>[]> => {
  try {
    let newPayload;

    dispatch({ type: LOGIN_IS_LOADING, payload: true });

    if (payload.step === "2fa") {
      newPayload = {
        bearerToken: payload.token,
        pin: payload.pin,
      };
    } else if (payload.oneTimeToken) {
      newPayload = {
        data: {
          oneTimeToken: payload.oneTimeToken,
        },
      };
    } else {
      newPayload = {
        data: {
          username: payload.login,
          password: payload.password,
        },
      };
    }

    return http.post("Authenticate/Login", newPayload).then(response => {
      const { data, token } = response;
      const { resetToken, message } = data;

      if (token) {
        cookies.set("bearerToken", token);
        dispatch({ type: LOGIN_IS_LOADING, payload: false });

        dispatch(loginSuccess({ ...response, step: "loggedin" }));
      } else {
        if (message === "MfaFailed") {
          return message;
        } else if (message === "PasswordExpired") {
          cookies.remove("bearerToken");
          history.push("/login?password-reset=2&token=" + resetToken);

          throw store.getState().intl.messages["app.accountexpired"];
        } else if (message === "UserLockedOut") {
          cookies.remove("bearerToken");
          history.push("/login?password-reset=1&user=");

          throw store.getState().intl.messages["app.accountlocked"];
        } else {
          throw Error("User or Password are incorrect.");
        }
      }
    });
  } catch (err) {
    dispatch(setLoading(false));
    cookies.remove("bearerToken");
    // @TODO: better german translation
    throw new Error(err.message || "Etwas ist schief gelaufen!");
  }
};

export const logOutLocally = (): ACTION => ({
  type: LOG_OUT,
});

export const logOut = () => async (dispatch: TDispatch): Promise<void> => {
  try {
    return http.post("Authenticate/logout").then(() => {
      dispatch(push("/login"));
      dispatch({ type: LOG_OUT, payload: null });
    });
  } catch (err) {
    throw err || "\n" + "Something went wrong!";
  }
};
