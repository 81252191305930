import React, { FC } from "react";

import { StyledButton } from "./styles";
import Spinner from "@components/Spinner";
import { TButton } from "@components/Button/types";

const OldButton: FC<TButton> = ({
  isLoading,
  type = "button",
  color = "primary",
  children,
  textButton = false,
  ...rest
}) => {
  return (
    <StyledButton tabIndex={0} type={type} color={color} textButton={textButton} {...rest}>
      {isLoading ? <Spinner /> : children && children}
    </StyledButton>
  );
};

export { OldButton };
