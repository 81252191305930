import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";

import { Form } from "@components/Form";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { Button, TextButton } from "@icr/react.uicomponents";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";

import { TProps } from "./types";
import { ObjectSchema } from "./schema";
import { createObject, changeObject } from "../ducks";
import { fetchObjectTypes } from "../../../../actions/enumerator";
import { fetchApplications } from "../../../../reducers/enumerator";
import { TDispatch } from "../../../../redux/types";
import { useDispatch } from "react-redux";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const ObjectsModal: FC<TProps> = props => {
  const { closeModal, initialData } = props;

  const dispatch: TDispatch = useDispatch();
  const formatMessage = useFormatMessage();
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();

  const [isEditing, setIsEditing] = useState<boolean>();
  const [objectTypesList, setObjectTypeList] = useState<Record<string, unknown>[]>();
  const [applicationsList, setApplicationsList] = useState<Record<string, unknown>[]>();

  useEffect(() => {
    setIsEditing(!!initialData.id);
  }, [initialData.id]);

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  // Fetch lists
  useEffect(() => {
    dispatch(fetchObjectTypes()).then(ret => setObjectTypeList(ret));
    dispatch(fetchApplications()).then(ret => setApplicationsList(ret));
  }, [dispatch]);

  const showSuccessMessage = action => {
    swalAlertWithCallback(
      {
        text: `Object has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const object = {
      ...formData,
      applicationCode: formData.applicationCode ? formData.applicationCode.value : undefined,
      objectType: formData.objectType ? formData.objectType.value : undefined,
    };

    if (isEditing) {
      swalConfirmationMessage({
        text: "Do you really want to change this object?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          // const objectToEdit = Object.keys(object).map(key => {
          //   return {
          //     op: "replace",
          //     path: `/${[key]}`,
          //     value: object[key],
          //   };
          // });

          changeObject(formData.id, object).then(res => {
            if (res.success) showSuccessMessage("changed");
          });
        }
      });
    } else {
      delete object.id;
      swalConfirmationMessage({
        text: "Do you really want to create this object?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Object",
      }).then(value => {
        if (value) {
          createObject(object).then(res => {
            if (res.success) showSuccessMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={ObjectSchema} defaultValues={initialData}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Input
              id="objectName"
              name="objectName"
              label={formatMessage("app.objectName", "Object Name")}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="applicationCode"
              name="applicationCode"
              label={formatMessage("app.applicationCode", "Application Code")}
              options={applicationsList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
          </Flex>
          <Flex mt="30px">
            <Select
              id="objectType"
              name="objectType"
              label={formatMessage("app.objectType", "Object Type")}
              options={objectTypesList?.map(option => ({
                value: option.id,
                label: option.description,
              }))}
            />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default ObjectsModal;
