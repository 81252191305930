import * as constants from "../../../constants";
import { ACTION } from "../../../shared/models/action";
import { deserialize, serialize } from "@api/jsonApiParser";
import http from "@api/http";
import { TDispatch } from "../../../redux/types";
import { TResponse } from "@api/types";

export const FETCHED_TRANSLATIONS = "FETCHED_TRANSLATIONS";
export const SET_FILTERING_CRITERIA_TRANSLATIONS = "SET_FILTERING_CRITERIA_TRANSLATIONS";
export const SET_TRANSLATIONS_PAGE_NO = "SET_TRANSLATIONS_PAGE_NO";
export const INCREMENT_TRANSLATIONS_PAGE_NO = "INCREMENT_TRANSLATIONS_PAGE_NO";

export type TranslationsState = {
  translations: any;
  translationsPageNo: any;
  moreTranslations: boolean;
  filter: any;
};

const initialState = {
  translations: [],
  translationsPageNo: 1,
  moreTranslations: true,
  filter: undefined,
};

export const TranslationsReducer = (state: TranslationsState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_TRANSLATIONS: {
      let translations = [];
      const moreTranslations = !(action.payload.length < constants.PAGINATION_SIZE);

      if (state.translationsPageNo === 1 || action.page === 1) {
        translations = [...action.payload];
      } else {
        translations = [...state.translations, ...action.payload];
      }

      return { ...state, translations: translations, moreTranslations: moreTranslations };
    }

    case SET_TRANSLATIONS_PAGE_NO: {
      const moreTranslations = !(
        state.translations.length <
        (state.translationsPageNo + 1) * constants.PAGINATION_SIZE
      );
      return { ...state, translationsPageNo: action.payload, moreTranslations: moreTranslations };
    }

    case SET_FILTERING_CRITERIA_TRANSLATIONS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_TRANSLATIONS_PAGE_NO: {
      return {
        ...state,
        translationsPageNo: state.translationsPageNo + 1,
      };
    }
  }
  return state;
};

export const setTranslationsPageNo = (payload: number): ACTION => ({
  type: SET_TRANSLATIONS_PAGE_NO,
  payload,
});

export const fetchTranslationList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: TDispatch): Promise<void> => {
    dispatch({ type: constants.LOADING, payload: true });

    let query = `Translation/TranslationPaginated?Page=${page}&PageSize=${pageSize}`;

    if (filter) {
      Object.keys(filter).forEach(key => {
        if ((key === "dynamicFilter" && filter[key]) || (key === "GenericText" && filter[key]))
          query += `&SearchGeneric=${filter[key]}`;
        else if (key !== "ListNotTranslated" && filter[key]) {
          query += `&Search=${key.replace("Filter", "")} ${
            key !== "TranslationId" && key !== "TextTranslated" ? "eq" : "co"
          } ${filter[key]}`;
        }
      });
      if (filter.ListNotTranslated) query += "&Search=TextTranslated noe"; // null or empty
    }

    return await http.get(query).then((response: TResponse) => {
      dispatch({
        type: FETCHED_TRANSLATIONS,
        payload: (response.data as Record<string, unknown>[]).map(translation =>
          deserialize(translation)
        ),
        page: page,
      });
      dispatch({ type: constants.LOADING, payload: false });
    });
  };
};

export const createTranslation = payload => {
  return http.post("Translation/", serialize(payload, "Translation"));
};

export const changeTranslation = (id: string, payload) => {
  return http.put("Translation/" + id, serialize(payload, "Translation"));
};

export const deleteTranslation = (id: string) => {
  return http.delete("Translation/" + id);
};

export const getTranslationById = (id: string) => {
  return http.get("Translation/" + id);
};

export const initializeTranslationApiLayer = () => {
  return http.post("Translation/InitializeTranslationApiLayer", null);
};

export const initializeTranslationBatch = payload => {
  return http.post(
    "Translation/InitializeTranslationBatch",
    serialize(payload, "TranslationBatch")
  );
};

export const incrementTranslationsPageNo = () => ({
  type: INCREMENT_TRANSLATIONS_PAGE_NO,
});
