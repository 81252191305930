import http from "../../../api/http";
import { TUsersState } from "./types";
import { LOADING, PAGINATION_SIZE } from "../../../constants";

import { TAction } from "@redux/types";
import { buildFilterQueryString, deserialize, serialize } from "@api/jsonApiParser";

import { Dispatch } from "redux";
import { TResponse } from "@api/types";

export const FETCHED_USERS = "FETCHED_USERS";
export const SET_FILTERING_CRITERIA_USERS = "SET_FILTERING_CRITERIA_USERS";
export const SET_ORDER_CRITERIA_USERS = "SET_ORDER_CRITERIA_USERS";
export const SET_USERS_PAGE_NO = "SET_USERS_PAGE_NO";
export const INCREMENT_USERS_PAGE_NO = "INCREMENT_USERS_PAGE_NO";
export const SET_FORM_USER = "SET_FORM_USER";

const initialState = {
  users: [],
  usersPageNo: 1,
  moreUsers: true,
  filter: undefined,
  order: [],
  formUser: {},
};

const UsersReducer = (state: TUsersState = initialState, action: any) => {
  switch (action.type) {
    case FETCHED_USERS: {
      if (action.payload) {
        let users = [];
        const moreusers = !(action.payload.length < PAGINATION_SIZE);

        if (state.usersPageNo === 1 || action.page === 1) {
          users = [...action.payload];
        } else {
          users = [...state.users, ...action.payload];
        }

        return { ...state, users: users, moreUsers: moreusers };
      }

      break;
    }

    case SET_USERS_PAGE_NO: {
      const moreUsers = !(state.users.length < (state.usersPageNo + 1) * PAGINATION_SIZE);
      return { ...state, usersPageNo: action.payload, moreUsers: moreUsers };
    }

    case SET_FILTERING_CRITERIA_USERS: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_USERS_PAGE_NO: {
      return {
        ...state,
        usersPageNo: state.usersPageNo + 1,
      };
    }

    case SET_FORM_USER: {
      return {
        ...state,
        formUser: action.payload.user,
      };
    }

    // Adds, removes or sets ordering criteria
    case SET_ORDER_CRITERIA_USERS: {
      switch (action.payload.method) {
        case "add":
          if (state.order.includes(action.payload.value)) {
            // if order for a field is set, remove it
            return {
              ...state,
              usersPageNo: 1,
              order: [...state.order.filter(order => order !== action.payload.value)],
            };
          } else {
            return {
              ...state,
              usersPageNo: 1,
              order: [...state.order.concat(action.payload.value)],
            };
          }
        case "remove":
          return {
            ...state,
            usersPageNo: 1,
            order: [...state.order.filter(order => order !== action.payload.value)],
          };
        case "set": {
          const orderedUsers: Array<string> = [];
          for (const key in action.payload.value) {
            if (action.payload.value[key] !== "") {
              orderedUsers.push(key + action.payload.value[key]);
            }
          }
          return { ...state, usersPageNo: 1, order: orderedUsers };
        }
        case "reset":
          return { ...state, usersPageNo: 1, order: [] };
      }
    }
  }
  return state;
};

export const setUsersPageNo = (payload: number): TAction => ({
  type: SET_USERS_PAGE_NO,
  payload,
});

export const setUsersOrderCriteria = (payload: any): TAction => ({
  type: SET_ORDER_CRITERIA_USERS,
  payload,
});

export const setUsersFilterCriteria = (payload: any): TAction => ({
  type: SET_FILTERING_CRITERIA_USERS,
  payload,
});

export const incrementUsersPageNo = () => ({
  type: INCREMENT_USERS_PAGE_NO,
});

export const fetchUsersList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `User/UserPaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: TResponse<Record<string, unknown>[]>) => {
        if (response.data)
          dispatch({
            type: FETCHED_USERS,
            payload: (response.data as Record<string, unknown>[]).map(users => deserialize(users)),
            page: page,
          });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const createUser = payload => {
  return http.post("User/", serialize(payload, "User"));
};

export const changeUser = (id: string, payload) => {
  return http.patch("User/" + id, payload);
};

export const updateUserRoles = payload => {
  return http.post("UserRole/PostUserRoleContainer/", serialize(payload, "UserRoleContainer"));
};

export const changeUserPassword = (id: string, password: string) => {
  const info = { id: id, password: password };
  return http.post("User/ChangePassword", serialize(info, "ChangePassword"));
};

export const deleteUser = (id: string) => {
  return http.delete("User/" + id);
};

export const getUserById = (id: string) => {
  return http.get("User/" + id);
};

export const setFormUser = (user: Record<string, unknown>): TAction => ({
  type: SET_FORM_USER,
  payload: { user },
});

export { UsersReducer };
