import React, { FC, useEffect, useState } from "react";
import { setLocale } from "yup";
import { Flex } from "reflexbox";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Form } from "@components/Form";
import { useFormatMessage } from "@hooks/formatMessage";
import { FormInput as Input } from "@components/Form/Input";
import { FormSelect as Select } from "@components/Form/Select";
import { FormCheckbox as Checkbox } from "@components/Form/Checkbox";
import { Button, TextButton } from "@icr/react.uicomponents";
import { ReactComponent as IconArrowLeftBlue } from "../../../../assets/icons/arrow-left-blue.svg";

import { useSwal } from "@hooks/useSwal";

import { TProps } from "./types";
import { ApplicationSchema } from "./schema";
import { TDispatch, TRootState } from "../../../../redux/types";
import { changeApplication, createApplication } from "../ducks";
import { fetchApplications } from "../../../../reducers/enumerator";

setLocale({
  mixed: {
    default: "app.requiredField",
    required: "app.requiredField",
  },
  string: {
    required: "app.requiredField",
  },
  number: {
    required: "app.requiredField",
  },
  object: {
    required: "app.requiredField",
  },
});

const ApplicationsModal: FC<TProps> = props => {
  const { closeModal } = props;

  const [applicationsList, setApplicationsList] = useState<Record<string, unknown>[]>();
  const [isEditing, setIsEditing] = useState<boolean>();

  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { formApplication } = useSelector(
    ({ ApplicationsReducer: { formApplication } }: TRootState) => ({
      formApplication,
    }),
    shallowEqual
  );

  useEffect(() => {
    document.body.classList.remove("backdrop");
  }, []);

  useEffect(() => {
    dispatch(fetchApplications()).then(ret => setApplicationsList(ret));

    setIsEditing(!!formApplication.id);
  }, [dispatch, formApplication.id]);

  const successfulMessage = (message: string) => {
    swalAlertWithCallback(
      {
        text: "Application has been " + message + "!",
        type: "success",
        confirmationText: "Ok!",
      },
      closeModal
    );
  };

  const onSubmit = formData => {
    const application = {
      ...formData,
      applicationCode: formData.applicationCode ? formData.applicationCode.value : undefined,
    };

    if (isEditing) {
      // Confirm action
      swalConfirmationMessage({
        text: "Do you really want to change this application?",
        // content: (),
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save changes",
      }).then(value => {
        if (value) {
          changeApplication(formData.id, application).then(res => {
            if (res.success) successfulMessage("changed");
          });
        }
      });
    } else {
      swalConfirmationMessage({
        text: "Do you really want to create this application?",
        type: "info",
        cancelationText: "Cancel",
        confirmationText: "Save new Application",
      }).then(value => {
        if (value) {
          delete application.id;

          createApplication(application).then(res => {
            if (res.success) successfulMessage("created");
          });
        }
      });
    }
  };

  return (
    <div className="modal">
      <div className="modal-header">
        <div className="modal-header-left">
          <TextButton id="backBtn" onClick={closeModal}>
            <Flex justifyContent="center">
              <Flex mr="10px" width="16px" height="16px" alignItems="center">
                <IconArrowLeftBlue />
              </Flex>
              {formatMessage("app.backToOverview")}
            </Flex>
          </TextButton>
        </div>
      </div>
      <Flex flexDirection="column" mb={20}>
        <Form onSubmit={onSubmit} schema={ApplicationSchema} defaultValues={formApplication}>
          <Flex>
            <Input id="id" name="id" hidden />
            <Select
              id="applicationCode"
              name="applicationCode"
              label={formatMessage("app.applicationCode", "Application")}
              options={applicationsList?.map(option => {
                return {
                  value: option.id,
                  label: option.description,
                };
              })}
            />
          </Flex>
          <Flex mt="30px">
            <Input id="url" name="url" label={formatMessage("app.url", "Url")} />
            <Checkbox
              id="debuggingMode"
              name="debuggingMode"
              label={formatMessage("app.debuggingMode", "Debugging Mode")}
              wrapperProps={{ width: "20%" }}
            />
          </Flex>
          <Flex mt="30px">
            <Input id="pathLocal" name="pathLocal" label={formatMessage("app.pathLocal", "Path")} />
          </Flex>
          <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
            <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal} type="button">
              {formatMessage("app.cancel", "Cancel")}
            </Button>
            <Button id="ModalBtn_save" type="submit">
              {formatMessage("app.save", "Save")}
            </Button>
          </Flex>
        </Form>
      </Flex>
    </div>
  );
};

export default ApplicationsModal;
