import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { TDispatch, TRootState } from "../../../redux/types";
import { useLocation } from "react-router-dom";
import { Flex } from "reflexbox";
import { useSwal } from "@hooks/useSwal";
import { useFormatMessage } from "@hooks/formatMessage";
import {
  setApplicationsPageNo,
  incrementApplicationsPageNo,
  fetchApplicationsList,
  deleteApplication,
  getApplicationById,
  setFormApplication,
} from "./ducks";

import {
  AddButton,
  ScrollableList,
  ListColumn,
  ExpandableRow,
  ListContainer,
  Text,
  EditIcon,
  TrashIcon,
  Input,
  Filter,
  Checkbox,
} from "@icr/react.uicomponents";

import "../../../styles/index.scss";
import "./Applications.scss";
import { getParameterByName } from "utils/toSearchParams";
import { deserialize } from "@api/jsonApiParser";
import ApplicationsModal from "./Modal/ApplicationsModal";

enum formMode {
  edit = "edit",
  password = "password",
}

const Applications: FC = () => {
  const [modalContent, setModalContent] = useState(undefined);
  const [modalIsOpen, setModalIsOpen] = useState(undefined);
  const { swalConfirmationMessage, swalAlertWithCallback } = useSwal();
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [genericTextFilter, setGenericTextFilter] = useState<string>("");

  const location = useLocation();
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { applicationsList, moreApplications, applicationsPageNo } = useSelector(
    ({
      ApplicationsReducer: { applications, moreApplications, applicationsPageNo },
    }: TRootState) => ({
      applicationsList: applications,
      moreApplications,
      applicationsPageNo,
    }),
    shallowEqual
  );

  if (location.search && location.search.length > 0) {
    setModalContent(getParameterByName("content", location.search));
    setModalIsOpen(!!modalContent);
  }

  useEffect(() => {
    dispatch(fetchApplicationsList()).then(() => dispatch(incrementApplicationsPageNo()));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreApplications) {
      setShowMoreSpinner(true);
      dispatch(
        fetchApplicationsList(applicationsPageNo, 10, {
          GenericText: genericTextFilter,
        })
      ).then(() => {
        setShowMoreSpinner(false);
        dispatch(incrementApplicationsPageNo());
      });
    }
  };

  const filterApplications = () => {
    dispatch(setApplicationsPageNo(1));
    dispatch(
      fetchApplicationsList(1, 10, {
        GenericText: genericTextFilter,
      })
    );
  };

  const clearFilters = () => {
    setGenericTextFilter("");

    dispatch(setApplicationsPageNo(1));
    dispatch(fetchApplicationsList(1, 10));
  };

  const openModal = (content, message?, entry?) => {
    if (content === formMode.edit) {
      getApplicationById(entry.id).then(ret => {
        if (ret) {
          dispatch(setFormApplication(deserialize(ret.data)));
          setModalIsOpen(true);
          setModalContent(content);
        }
      });
    } else {
      dispatch(setFormApplication(deserialize({ id: entry.id })));
      setModalIsOpen(true);
      setModalContent(content);
    }
  };

  const saveHistoryAndFilterApplications = () => filterApplications();

  const closeModal = () => {
    setModalIsOpen(false);
    saveHistoryAndFilterApplications();

    // TODO: this.props.fetchCompanies().then(res => this.setState({ parentCompanyList: res }));
    // TODO: change the location of the list to the modal component
  };

  const showSuccessMessage = (action, callbackFunction) => {
    swalAlertWithCallback(
      {
        text: `Application has been ${action}!`,
        type: "success",
        confirmationText: "Ok!",
      },
      callbackFunction
    );
  };

  const remove = id => {
    swalConfirmationMessage({
      text: "Do you really want to remove this application?",
      type: "info",
      cancelationText: "Cancel",
      confirmationText: "Remove application",
    }).then(value => {
      if (value) {
        deleteApplication(id).then(ret => {
          if (ret) showSuccessMessage("removed", saveHistoryAndFilterApplications);
        });
      }
    });
  };

  return (
    <ListContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Large Modal"
        className={"large-modal"}
        overlayClassName={"large-modal-overlay"}
      >
        {modalIsOpen && <ApplicationsModal closeModal={closeModal} />}
      </Modal>

      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%">
          <AddButton
            id="addApplicationBtn"
            p="0 32px"
            m="32px 0"
            label={formatMessage("app.addnewapplication", "Add Application")}
            onClick={() => {
              dispatch(setFormApplication({}));
              setModalIsOpen(true);
            }}
          />
          <ScrollableList
            items={applicationsList}
            onLoadMore={handleLoadMore}
            noItemsFoundMessage={formatMessage("app.noRecordAvailable")}
            renderHeader={
              <Flex width="100%">
                <ListColumn>
                  <Text>{formatMessage("app.application", "Application")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.url", "Url")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.pathLocal", "Path")}</Text>
                </ListColumn>
                <ListColumn>
                  <Text>{formatMessage("app.debuggMode", "Debug")}</Text>
                </ListColumn>
                <ListColumn></ListColumn>
              </Flex>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                key={"applicationRow" + index}
                id={item.id as string}
                expandable={false}
                top={
                  <Flex width="100%">
                    <ListColumn>
                      <Text font="semiBold"> {item.applicationName}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.url}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Text> {item.pathLocal}</Text>
                    </ListColumn>
                    <ListColumn>
                      <Checkbox id="TableDebuggModeInput" checked={item.debuggMode as boolean} />
                    </ListColumn>
                    <ListColumn icon>
                      <EditIcon
                        id={"changeEntry" + item.id}
                        title="Edit Application"
                        onClick={() => openModal(formMode.edit, "", item)}
                      />
                      <TrashIcon
                        id={"deleteEntry" + item.id}
                        title="Delete Application"
                        onClick={() => remove(item.id)}
                      />
                    </ListColumn>
                  </Flex>
                }
              />
            )}
          />
        </Flex>
        <Filter
          id="filterArea"
          searchButtonText={formatMessage("app.search", "Search")}
          onClickSearchButton={filterApplications}
          clearButtonText={formatMessage("app.clear", "Clear")}
          onClickClearButton={clearFilters}
          open={true}
        >
          <Input
            id="dynamicSearch"
            label="Dynamic Filter"
            value={genericTextFilter}
            placeholder={formatMessage("app.anyfield", "Any Field")}
            onChange={e => setGenericTextFilter(e.target.value)}
          />
        </Filter>
      </Flex>
    </ListContainer>
  );
};

export default Applications;
