import React, { FC, useEffect, useState } from "react";
import { TDispatch, TRootState } from "../../../redux/types";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Flex } from "reflexbox";

import {
  setRolesPageNo,
  incrementRolesPageNo,
  // setRolesOrderCriteria,
  // setRolesFilterCriteria,
  fetchRolesList,
} from "./ducks";

import { Text } from "@components/Text";
import Drawer from "@components/Drawer";
import ExpandableRow from "@components/ExpandableRow";
import { useFormatMessage } from "@hooks/formatMessage";
import { Column } from "@components/ExpandableRow/styles";
import { OldButton } from "@components/Button";
import { OldInput } from "@components/OldInput";
import { ScrollableList } from "@components/ScrollableList";

import { ReactComponent as IconFilter } from "../../../assets/icons/filter.svg";
import "../../../styles/index.scss";
import "./Roles.scss";

const Roles: FC = () => {
  const [filtersOpened, setFiltersOpened] = useState(window.innerWidth >= 1400);
  const [showMoreSpinner, setShowMoreSpinner] = useState(false);
  const [rolesFilter, setRolesFilter] = useState({
    GenericText: null,
  });

  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { rolesList, moreRoles, rolesPageNo } = useSelector(
    ({ RolesReducer: { roles, moreRoles, rolesPageNo } }: TRootState) => ({
      rolesList: roles,
      moreRoles,
      rolesPageNo,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(fetchRolesList()).then(() => dispatch(incrementRolesPageNo()));
  }, [dispatch]);

  const handleLoadMore = () => {
    if (!showMoreSpinner && moreRoles) {
      setShowMoreSpinner(true);
      dispatch(fetchRolesList(rolesPageNo, 10, rolesFilter)).then(() => {
        setShowMoreSpinner(false);
        dispatch(incrementRolesPageNo());
      });
    }
  };

  const changeFilterOpened = () => {
    setFiltersOpened(prevState => !prevState);
  };

  const filterRoles = () => {
    dispatch(setRolesPageNo(1));
    dispatch(fetchRolesList(1, 10, rolesFilter));
  };

  const clearFilters = () => {
    setRolesFilter({
      GenericText: null,
    });

    dispatch(setRolesPageNo(1));
    dispatch(fetchRolesList(1, 10));
  };

  return (
    <>
      <Flex pl={["10px", "80px"]} pr={["10px", 0]} height="100%" width="100%">
        <Flex flexDirection="column" width="100%" m="32px 0">
          <ScrollableList
            items={rolesList}
            onLoadMore={handleLoadMore}
            renderHeader={
              <>
                <Column>
                  <Text>{formatMessage("app.username", "Username")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.role", "Role")}</Text>
                </Column>
                <Column>
                  <Text>{formatMessage("app.application", "Application")}</Text>
                </Column>
                <Column icon>
                  <Text>
                    <div
                      id="toggleFilterArea"
                      className="icon-filter"
                      tabIndex={0}
                      onClick={e => {
                        e.stopPropagation();
                        changeFilterOpened();
                      }}
                    >
                      <IconFilter />
                    </div>
                  </Text>
                </Column>
              </>
            }
            renderItem={(item, index) => (
              <ExpandableRow
                data-cy="userRow"
                key={"userRow" + index}
                length={index}
                id={item.id as string}
                translucent={false}
                expandable={false}
                tab={""}
                top={
                  <React.Fragment>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.username}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.roleName}
                        </Text>
                      </div>
                    </Column>
                    <Column>
                      <div className="d-flex flex-row showChildOnHover">
                        <Text light={false} fontSize="16px">
                          {item.applicationName}
                        </Text>
                      </div>
                    </Column>
                  </React.Fragment>
                }
              />
            )}
          />
        </Flex>
        <Drawer id="filterArea" opened={filtersOpened}>
          <div className="paddingContainer">
            <p className="colorGrey mb-1">Dynamic Filter</p>
            <div data-cy="dynamicFilterByField">
              <OldInput
                id="dynamicSearch"
                name="dynamicSearch"
                key="dynamicSearch"
                inputWrapperClass="text-input filter-input"
                placeholder={formatMessage("app.anyfield", "Any Field")}
                value={rolesFilter.GenericText}
                onChange={(name, value) =>
                  setRolesFilter(prevState => ({ ...prevState, GenericText: value }))
                }
                validationMessageConfig={null /* this.validationMessageConfig() */}
                type="text"
                inputWrapperStyle={{}}
                filtering
              />
            </div>

            <div className="breaker mb-5" />

            <div className="modal-buttons">
              <OldButton id="buttonClearSearch" textButton mr="10px" onClick={clearFilters}>
                {formatMessage("app.clear", "Clear")}
              </OldButton>

              <OldButton id="buttonSearch" textButton mr="10px" onClick={filterRoles}>
                {formatMessage("app.search", "Search")}
              </OldButton>
            </div>
          </div>
        </Drawer>
      </Flex>
    </>
  );
};

export default Roles;
