import * as constants from "../constants/index";
import { TAction } from "@redux/types";

export const FETCHED_ROLES = "FETCHED_ROLES";
export const SET_FILTERING_CRITERIA_ROLES = "SET_FILTERING_CRITERIA_ROLES";
export const SET_ROLES_PAGE_NO = "SET_ROLES_PAGE_NO";
export const SET_ORDER_CRITERIA_ROLES = "SET_ORDER_CRITERIA_ROLES";
export const ROLES_IS_LOADING = "ROLES_IS_LOADING";

export interface TRolesState {
  roles: any;
  rolesPageNo: any;
  moreRoles: any;
  filter: any;
  order: Array<string>;
  rolesIsLoading: boolean;
}

const initialState = {
  roles: [],
  rolesPageNo: 1,
  moreRoles: true,
  filter: {},
  order: [],
  rolesIsLoading: false,
};

const roles = (state: TRolesState = initialState, action: TAction): TRolesState => {
  const orderedRoles: Array<string> = [];
  const { type, payload } = action;

  switch (type) {
    case SET_ROLES_PAGE_NO: {
      const moreRoles = !(state.roles.length < (state.rolesPageNo + 1) * constants.PAGINATION_SIZE);
      return { ...state, rolesPageNo: action.payload, moreRoles: moreRoles };
    }

    case FETCHED_ROLES: {
      const { roles, page } = payload;

      let newRoles = [];
      const moreRoles = !(roles.length < constants.PAGINATION_SIZE);

      if (state.rolesPageNo === 1 || page === 1) {
        newRoles = [...roles];
      } else {
        newRoles = [...state.roles, ...roles];
      }
      return { ...state, roles: newRoles, moreRoles: moreRoles };
    }

    case SET_FILTERING_CRITERIA_ROLES: {
      if (!action.payload) {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload };
      }
    }

    case ROLES_IS_LOADING: {
      return {
        ...state,
        rolesIsLoading: payload,
      };
    }

    // Adds, removes or sets ordering criteria
    case SET_ORDER_CRITERIA_ROLES: {
      switch (action.payload.method) {
        case "add":
          if (state.order.includes(action.payload.value)) {
            // if order for a field is set, remove it
            return {
              ...state,
              rolesPageNo: 1,
              order: [...state.order.filter(order => order !== action.payload.value)],
            };
          } else {
            return {
              ...state,
              rolesPageNo: 1,
              order: [...state.order.concat(action.payload.value)],
            };
          }
        case "remove":
          return {
            ...state,
            rolesPageNo: 1,
            order: [...state.order.filter(order => order !== action.payload.value)],
          };
        case "set":
          for (const key in action.payload.value) {
            if (action.payload.value[key] !== "") {
              orderedRoles.push(key + action.payload.value[key]);
            }
          }
          return { ...state, rolesPageNo: 1, order: orderedRoles };
        case "reset":
          return { ...state, rolesPageNo: 1, order: [] };
      }
    }
  }

  return state;
};

export default roles;
