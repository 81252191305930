import { Dispatch } from "redux";
import http from "../../../api/http";
import { TRolesState } from "./types";
import { TResponse } from "../../../api/types";
import { TAction } from "../../../redux/types";
import { LOADING, PAGINATION_SIZE } from "../../../constants";
import { buildFilterQueryString, deserialize } from "../../../api/jsonApiParser";

export const FETCHED_ROLES = "FETCHED_ROLES";
export const SET_FILTERING_CRITERIA_ROLES = "SET_FILTERING_CRITERIA_ROLES";
export const SET_ROLES_PAGE_NO = "SET_ROLES_PAGE_NO";
export const INCREMENT_ROLES_PAGE_NO = "INCREMENT_ROLES_PAGE_NO";

const initialState = {
  roles: [],
  rolesPageNo: 1,
  moreRoles: true,
  filter: undefined,
  order: [],
};

export const setRolesPageNo = (payload: number): TAction => ({
  type: SET_ROLES_PAGE_NO,
  payload,
});

export const incrementRolesPageNo = () => ({
  type: INCREMENT_ROLES_PAGE_NO,
});

// export const setRolesOrderCriteria = (payload: any): ACTION => ({
//   type: SET_ORDER_CRITERIA_ROLES,
//   payload,
// });

export const fetchRolesList = (page = 1, pageSize = 10, filter?: Record<string, unknown>) => {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch({ type: LOADING, payload: true });

    const query = `UserRole/UserRolePaginated?Page=${page}&PageSize=${pageSize}`;

    return await http
      .get(filter ? buildFilterQueryString(filter, query) : query)
      .then((response: any) => {
        dispatch({
          type: FETCHED_ROLES,
          payload: response.data.map(roles => deserialize(roles)),
          page: page,
        });

        dispatch({ type: LOADING, payload: false });
      });
  };
};

export const deleteRole = (id: string): Promise<TResponse> => {
  return http.delete("UserRole/" + id);
};

export const getRoleById = (id: string): Promise<TResponse> => {
  return http.get("UserRole/" + id);
};

export const fetchRolesByUserId = async (id: string): Promise<Record<string, unknown>[]> => {
  return await http
    .get(`UserRole/GetRolesByUserId/${id}`)
    .then((response: TResponse<Record<string, unknown>[]>) =>
      response.data.map(roles => deserialize(roles))
    );
};

const roles = (state: TRolesState = initialState, action: any) => {
  //   const orderedRoles: Array<string> = [];

  switch (action.type) {
    case SET_ROLES_PAGE_NO: {
      // console.log("reducer loading", action);
      const moreRoles = !(state.roles.length < (state.rolesPageNo + 1) * PAGINATION_SIZE);
      return { ...state, rolesPageNo: action.payload, moreRoles: moreRoles };
    }

    case FETCHED_ROLES: {
      let roles = [];
      const moreRoles = !(action.payload.length < PAGINATION_SIZE);

      if (state.rolesPageNo === 1 || action.page === 1) {
        roles = [...action.payload];
      } else {
        roles = [...state.roles, ...action.payload];
      }
      return { ...state, roles: roles, moreRoles: moreRoles };
    }

    case SET_FILTERING_CRITERIA_ROLES: {
      if (action.payload === "clearFilter") {
        return {
          ...state,
          filter: undefined,
        };
      } else {
        return { ...state, filter: action.payload.value };
      }
    }

    case INCREMENT_ROLES_PAGE_NO: {
      return {
        ...state,
        rolesPageNo: state.rolesPageNo + 1,
      };
    }

    // Adds, removes or sets ordering criteria
    // case SET_ORDER_CRITERIA_ROLES: {
    //   switch (action.payload.method) {
    //     case "add":
    //       if (state.order.includes(action.payload.value)) {
    //         // if order for a field is set, remove it
    //         return {
    //           ...state,
    //           rolesPageNo: 1,
    //           order: [...state.order.filter(order => order !== action.payload.value)],
    //         };
    //       } else {
    //         return {
    //           ...state,
    //           rolesPageNo: 1,
    //           order: [...state.order.concat(action.payload.value)],
    //         };
    //       }
    //     case "remove":
    //       return {
    //         ...state,
    //         rolesPageNo: 1,
    //         order: [...state.order.filter(order => order !== action.payload.value)],
    //       };
    //     case "set":
    //       for (const key in action.payload.value) {
    //         if (action.payload.value[key] !== "") {
    //           orderedRoles.push(key + action.payload.value[key]);
    //         }
    //       }
    //       return { ...state, rolesPageNo: 1, order: orderedRoles };
    //     case "reset":
    //       return { ...state, rolesPageNo: 1, order: [] };
    //   }
    // }
  }

  return state;
};

export default roles;
