import * as constants from "../constants";
import { TDispatch } from "../redux/types";

import http from "@api/http";
import { TResponse } from "@api/types";
import { deserialize } from "@api/jsonApiParser";

export const fetchTenants = () => async (
  dispatch: TDispatch
): Promise<Record<string, unknown>[]> => {
  dispatch({ type: constants.LOADING, payload: true });

  return await http.get("Tenant/").then((response: TResponse<Record<string, unknown>[]>) => {
    dispatch({ type: constants.LOADING, payload: false });
    return (response.data as Record<string, unknown>[]).map(user => deserialize(user));
  });
};
