import React, { FC } from "react";
import { Flex } from "reflexbox";
import { TDispatch, TRootState } from "@redux/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Checkbox,
  FormSelect as Select,
  FormInput as Input,
  FormInputDate as InputDate,
  useForm,
} from "@icr/react.uicomponents";

import { useFormatMessage } from "@hooks/formatMessage";
import { buildApplicationIdentifier, TApplication } from "@utils/applications";

import { setFormUser } from "@features/User/Users/ducks";
import { TStep1, TStep2, TStep3 } from "@features/User/Users/Modal/types";
import { Step1Schema, Step2Schema, Step3Schema } from "@features/User/Users/Modal/schema";

const Step1: FC<TStep1> = ({
  onSubmit,
  manualErrors,
  gendersList,
  languagesList,
  companiesList,
  closeModal,
  initialData,
}) => {
  const formatMessage = useFormatMessage();

  const { genderIsLoading, languagesIsLoading, companiesIsLoading } = useSelector(
    ({
      EnumeratorReducer: { genderIsLoading, languagesIsLoading },
      CompaniesReducer: { companiesIsLoading },
    }: TRootState) => ({
      genderIsLoading,
      languagesIsLoading,
      companiesIsLoading,
    }),
    shallowEqual
  );

  return (
    <Form
      onSubmit={onSubmit}
      schema={Step1Schema}
      manualErrors={manualErrors}
      defaultValues={initialData}
    >
      <Flex>
        <Input id="id" name="id" hidden />
        <Input
          id="firstName"
          name="firstName"
          label={formatMessage("app.firstName", "Firstname")}
        />
        <Input
          id="middleName"
          name="middleName"
          label={formatMessage("app.middleName", "Middlename")}
        />
        <Input id="lastName" name="lastName" label={formatMessage("app.lastName", "Lastname")} />
      </Flex>
      <Flex mt="30px">
        <InputDate
          id="birthDate"
          name="birthDate"
          label={formatMessage("app.birthDate", "Birthdate")}
        />
        <Input
          id="socialSecurityNumber"
          name="socialSecurityNumber"
          label={formatMessage("app.socialSecurityNumber", "Social Security Number")}
        />
        <Select
          id="sex"
          name="sex"
          label={formatMessage("app.gender", "Gender")}
          options={gendersList?.map(option => ({
            value: option.id,
            label: option.name,
          }))}
          isLoading={genderIsLoading}
          isDisabled={genderIsLoading}
        />
      </Flex>
      <Flex mt="30px">
        <Input
          id="completeAddress"
          name="completeAddress"
          type="text"
          label={formatMessage("app.completeAddress", "Complete Address")}
        />
      </Flex>
      <Flex mt="30px">
        <Select
          id="language"
          name="language"
          label={formatMessage("app.language", "Language")}
          options={languagesList?.map(option => ({
            value: option.id,
            label: option.name,
          }))}
          isLoading={languagesIsLoading}
          isDisabled={languagesIsLoading}
        />
        <Select
          id="companyId"
          name="companyId"
          label={formatMessage("app.company", "Company")}
          options={companiesList?.map(option => ({
            value: option.id,
            label: option.companyName,
          }))}
          isLoading={companiesIsLoading}
          isDisabled={companiesIsLoading}
        />
      </Flex>

      <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
        <Button id="ModalBtn_cancel" mr="10px" onClick={closeModal}>
          {formatMessage("app.cancel")}
        </Button>
        <Button id="ModalBtn_save" type="submit">
          {formatMessage("app.next")}
        </Button>
      </Flex>
    </Form>
  );
};

const Step2: FC<TStep2> = ({ onSubmit, isEditing, manualErrors, initialData, back }) => {
  const formatMessage = useFormatMessage();

  return (
    <Form
      onSubmit={onSubmit}
      schema={Step2Schema}
      manualErrors={manualErrors}
      defaultValues={initialData}
    >
      <Flex>
        {!isEditing && (
          <Input
            id="username"
            name="username"
            type="text"
            label={formatMessage("app.username", "Username")}
          />
        )}
        <Input id="email" name="email" type="text" label={formatMessage("app.email", "Email")} />
        {isEditing && (
          <Input
            id="mobileNumber"
            name="mobileNumber"
            type="text"
            label={formatMessage("app.mobileNumber", "Mobile Number")}
          />
        )}
      </Flex>
      {!isEditing && (
        <Flex mt="30px">
          <Input
            id="mobileNumber"
            name="mobileNumber"
            type="text"
            label={formatMessage("app.mobileNumber", "Mobile Number")}
          />
          <Input
            id="password"
            name="password"
            type="password"
            label={formatMessage("app.password", "Password")}
          />
        </Flex>
      )}
      <Flex mt="30px">
        {!isEditing && (
          <Input
            id="passwordrepeat"
            name="passwordrepeat"
            type="password"
            wrapperProps={{ flex: 1 }}
            label={formatMessage("app.passwordRepeat")}
          />
        )}
        <Flex pl="15px" pr="15px" flex={1}>
          <Checkbox
            id="mustChangePwd"
            name="mustChangePwd"
            wrapperProps={{ width: "25%", flex: 1 }}
            text={formatMessage("app.passwordMustBeChanged")}
          />
          <Checkbox
            id="passwordNeverExpires"
            name="passwordNeverExpires"
            text={formatMessage("app.passwordNeverExpires")}
            wrapperProps={{ width: "25%", flex: 1 }}
          />
        </Flex>
      </Flex>
      <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
        <Button id="ModalBtn_cancel" mr="10px" onClick={back}>
          {formatMessage("app.back")}
        </Button>
        <Button id="ModalBtn_save" type="submit">
          {formatMessage("app.next")}
        </Button>
      </Flex>
    </Form>
  );
};

const Step3: FC<TStep3> = ({
  onSubmit,
  applicationsList,
  rolesList,
  back,
  initialData,
  isLoading,
}) => {
  const formatMessage = useFormatMessage();
  const dispatch: TDispatch = useDispatch();

  const { formUser } = useSelector(
    ({ UsersReducer: { formUser } }: TRootState) => ({
      formUser,
    }),
    shallowEqual
  );

  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: initialData,
    resolver: yupResolver(Step3Schema),
  });

  const handleBack = () => {
    const multipleValues = getValues();

    dispatch(
      setFormUser({
        ...formUser,
        ...multipleValues,
      })
    );

    back();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Input id="id" name="id" hidden />
      {applicationsList?.map((application: TApplication, index) => {
        const applicationIdentifier = buildApplicationIdentifier(application);

        return (
          <Flex mt={index === 0 ? "" : "30px"}>
            <Select
              id={applicationIdentifier}
              name={applicationIdentifier}
              key={applicationIdentifier}
              label={formatMessage(
                "app.roleOn" + applicationIdentifier.slice(1),
                "Role On " + application.description
              )}
              options={rolesList?.map(option => ({
                value: option.id,
                label: option.roleName,
              }))}
              control={control}
              setValue={setValue}
            />
          </Flex>
        );
      })}
      <Flex mt={20} width="100%" justifyContent="flex-end" pr="15px">
        <Button id="ModalBtn_cancel" mr="10px" onClick={handleBack} disabled={isLoading}>
          {formatMessage("app.back")}
        </Button>
        <Button id="ModalBtn_save" type="submit" isLoading={isLoading} disabled={isLoading}>
          {formatMessage("app.save")}
        </Button>
      </Flex>
    </form>
  );
};

export { Step1, Step2, Step3 };
